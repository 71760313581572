<script lang="ts" setup>
import { ref, computed, watch, nextTick } from "vue";

import "ol/ol.css";
import { OSM } from "ol/source";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { Attribution, defaults as defaultControls } from "ol/control";
import { Style } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Icon from "ol/style/Icon";
import markerImg from "@/assets/img/mapMarkerBlue.png";
import markerINNAXImg from "@/assets/img/mapMarkerINNAX.png";

const props = defineProps({
  locationmapdata: {
    type: String,
    required: true,
  },
});

const coordinatesKnown = ref(false);
const isOutgrayed = computed(() => (coordinatesKnown.value ? "" : "outgrayed"));

const mapElementId = "locationmap";

    watch(
  () => props.locationmapdata,
  async () => {
    await nextTick(); //nextTick is used to wait for the next update cycle, ensuring that the map container is visible.
    const mapdata = JSON.parse(props.locationmapdata);
    console.log("LocationMap locationmapdata ", mapdata);
    initiateMap(mapdata);

  },
  { immediate: true }
);


function initiateMap(mapData) {
  // create title layer
  let rasterLayer = new TileLayer({
    source: new OSM(),
  });

  let attribution = new Attribution({
    collapsible: true,
    collapsed: true,
  });

  let mapConfig;
  if (mapData) {
    mapConfig = {
      target: mapElementId,

      layers: [rasterLayer],

      view: new View({
        projection: "EPSG:3857",
        center: fromLonLat([mapData.Center.Longitude, mapData.Center.Latitude]),
        zoom: mapData.ZoomLevel,
        constrainResolution: true,
      }),

      controls: defaultControls({ attribution: false }).extend([attribution]),
    };
    coordinatesKnown.value = true;
  } else {
    // Toon de kaart van Nederland indien geen mapData
    mapConfig = {
      target: mapElementId,

      layers: [rasterLayer],

      view: new View({
        projection: "EPSG:3857",
        center: fromLonLat([5.5556715, 52.0391309]), // midden Nederland
        zoom: 5,
        constrainResolution: true,
      }),

      controls: defaultControls({ attribution: false }).extend([attribution]),
    };
  }

  let markerLayerINNAX;

  // Markers voor INNAX Veenendaal, Tilburg, Heerenveen resp. Almere.
  (markerLayerINNAX = new VectorLayer({
    source: new VectorSource({
      features: [
        new Feature({
          geometry: new Point(fromLonLat([5.5588723270339795, 52.01654763478699])),
          name: "Veenendaal",
        }),
        new Feature({
          geometry: new Point(fromLonLat([5.053956783592722, 51.56176009484667])),
          name: "Tilburg",
        }),
        new Feature({
          geometry: new Point(fromLonLat([5.9348174278006764, 52.957144627733214])),
          name: "Heerenveen",
        }),
        new Feature({
          geometry: new Point(fromLonLat([5.194113227787618, 52.353568940649716])),
          name: "Almere",
        }),
      ],
    }),
    style: new Style({
      image: new Icon({
        anchorOrigin: "bottom-left",
        anchor: [13, 0.5],
        anchorXUnits: "pixels",
        scale: 0.15,
        src: markerINNAXImg,
      }),
    }),
  })),
    mapConfig.layers.push(markerLayerINNAX);

  if (mapData) {
    if (mapData.Coordinates.length > 0) {
      let markerLayer;
      mapData.Coordinates.forEach((marker) => {
        (markerLayer = new VectorLayer({
          source: new VectorSource({
            features: [
              new Feature({
                geometry: new Point(fromLonLat([marker.Longitude, marker.Latitude])),
              }),
            ],
          }),
          style: new Style({
            image: new Icon({
              anchorOrigin: "bottom-left",
              anchor: [13, 0.5],
              anchorXUnits: "pixels",
              scale: 0.75,
              src: markerImg,
            }),
          }),
        })),
          mapConfig.layers.push(markerLayer);
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  let map = new Map(mapConfig);
}
</script>

<template>
  <v-layout>
    <div :id="mapElementId" class="location-map" :class="isOutgrayed"></div>
  </v-layout>
</template>
