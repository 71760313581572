var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-layout", [
    _c("div", {
      staticClass: "location-map",
      class: _setup.isOutgrayed,
      attrs: { id: _setup.mapElementId },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }